import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
  Typography,
  List,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Comment from './Comment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.2),
  },
  heading: {
    ...theme.typography.button,
    fontWeight: "bold",
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
  },
  list: {
    padding: 0,
    width: "100%",
  },
  accordion: {
    padding: 0,
  }
}));

export default function CommentSection(props) {
  const classes = useStyles();

  const { commentAdder, name, quotes } = props;

  return (
    <div className={classes.root}>
    <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.list}>
            {quotes.map(quote => (
              <Comment commentAdder={commentAdder} content={quote.content} key={quote.id}/>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
