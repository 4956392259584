import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Button,
  Snackbar,
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import CreateIcon from '@material-ui/icons/Create';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Chip from '@material-ui/core/Chip';


import { CURSOR } from './ReportGenerator';


const useStyles = makeStyles((theme) => ({
  root: {
    "& > * > * ": {
      margin: theme.spacing(0.25),
    },
    position: "sticky",
    top: 0,
  },
  paper: {
    padding: theme.spacing(1),
  },
  comment: {
    '&:hover': {
      backgroundColor: "#ffe0e0",
      cursor: "pointer",
    },
  },
  reportEditor: {
    width: "100%",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    // resize: "none",
    // minHeight: "30px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  wordCount: {
    marginTop: theme.spacing(1),
    float: "right",
    backgroundColor: wordCount => {
      if (wordCount < 80)
        return theme.palette.warning.main
      else if (wordCount < 100)
        return theme.palette.success.main
      else if (wordCount < 120)
        return theme.palette.warning.main
      else
        return theme.palette.error.main
    }
  }
}));


export default function CommentCombiner(props) {

  const { comments, removeCommentAt, setComments } = props;

  const [snackBarAlertOpen, setSnackBarAlertOpen] = useState(false);

  const [editorContents, setEditorContents] = useState("");

  const wordCount = findWordCount(editorContents);
  const classes = useStyles(wordCount);

  function findWordCount(contents) {
    if (contents === "") {
      return 0;
    }
    else {
      return editorContents.trim().split(" ").length;
    }
  }

  function editReport() {
    const relevantComments = comments.filter(comment => comment !== CURSOR);
    setEditorContents(relevantComments.join(' '));
  };

  function updateReport(e) {
    setEditorContents(e.target.value);
  };

  function moveCursor(position) {
    const cursorLocation = comments.indexOf(CURSOR)
    comments.splice(cursorLocation, 1);

    let newCursorLocation = cursorLocation + position;

    if (newCursorLocation < 0) {
      newCursorLocation = 0;
    } else if (newCursorLocation > comments.length) {
      newCursorLocation = comments.length;
    }
    comments.splice(newCursorLocation, 0, CURSOR);

    setComments([...comments]);
  }

  function moveCursorLeft() {
    moveCursor(-1);
  }

  function moveCursorRight() {
    moveCursor(1);
  }

  function copyFinalReport() {
    navigator.clipboard.writeText(editorContents);
    setSnackBarAlertOpen(true);
  }

  function handleAlertClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarAlertOpen(false);
  }
  const deleteTooltip = "Click to delete the sentence.";
  const cursorTooltip = "The pen represents where the next sentence will be placed into the report. Move the pen with the arrows below.";
  const leftArrowTooltip = "Move the pen left, and adjust where the next sentence will be placed.";
  const rightArrowTooltip = "Move the pen right, and adjust where the next sentence will be placed.";
  const editTooltip = "Copies the current report into the space below to allow edits.";
  const copyTooltip = "Copies the edited report to your clipboard.";
  const wordCountTooltip = "Shows the current number of words in the editor."

  return (
    <Box className={classes.root} p={3}>
      <Paper className={classes.paper}>
        <p>
          {comments.map((comment, index) => (
             comment === "$$$Cursor$$$"
               ? <Tooltip title={cursorTooltip} placement="top">
                   <CreateIcon title={cursorTooltip} fontSize="small" key={"cursor"}/>
                 </Tooltip>
               : <Tooltip title={deleteTooltip} placement="left">
                   <span
                     className={ classes.comment }
                     onClick={() => removeCommentAt(index)}
                     key={comment}
                   >
                     {comment}&nbsp;
                   </span>
                 </Tooltip>
          ))}
        </p>
      </Paper>

      <Tooltip title={editTooltip}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={editReport}
        >
          Edit
          <ArrowDownwardIcon/>
        </Button>
      </Tooltip>
      <Tooltip title={leftArrowTooltip}><Button className={classes.button} variant="contained" color="primary" onClick={moveCursorLeft}><ArrowBackIcon/></Button></Tooltip>
      <Tooltip title={rightArrowTooltip}><Button className={classes.button} variant="contained" color="primary" onClick={moveCursorRight}><ArrowForwardIcon/></Button></Tooltip>
      <TextareaAutosize
        className={classes.reportEditor}
        value={editorContents}
        onChange={updateReport}
      ></TextareaAutosize>
      <Tooltip title={copyTooltip}>
        <Button onClick={copyFinalReport} className={classes.button} variant="contained" color="primary">Copy</Button>
      </Tooltip>
      <Tooltip title={wordCountTooltip}>
        <Chip className={classes.wordCount} label={wordCount} />
      </Tooltip>
      <Snackbar
        open={snackBarAlertOpen}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert onClose={handleAlertClose} severity="success">
          Report copied!
        </Alert>
      </Snackbar>
    </Box>
  );
};
