import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio, Box } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles((theme) => ({
  root: {
  },
  textField: {
    margin: theme.spacing(1),
    width: '20ch',
  },
  radioControl: {
    paddingRight: 0,
  }
}));

export default function StudentInfo(props) {
  const classes = useStyles();

  const { studentInfoSetter, keyStage, keyStageSetter } = props;
  const [pronounChoice, setPronounChoice] = useState("female");
  const [name, setName] = useState("");
  const [targetGrade, setTargetGrade] = useState("");
  const [achievedGrade, setAchievedGrade] = useState("");
  // const [keyStage, setKeyStage] = useState("KS3");


  useEffect(() => {
    const pronounsMap = {
      female: {
        "he": "she", "him": "her", "his": "her",
        "He": "She", "Him": "Her", "His": "Her",
      },
      male: {
        "he": "he", "him": "him", "his": "his",
        "He": "He", "Him": "Him", "His": "His",
      },
    }

    studentInfoSetter({
      name: name,
      target_grade: targetGrade,
      achieved_grade: achievedGrade,
      ...pronounsMap[pronounChoice],
    });
  }, [name, targetGrade, achievedGrade, pronounChoice, studentInfoSetter]);

  const handlePronounsChange = (event) => {
    setPronounChoice(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleTargetGradeChange = (event) => {
    setTargetGrade(event.target.value);
  };

  const handleAchievedGradeChange = (event) => {
    setAchievedGrade(event.target.value);
  };

  const handleKeyStageChange = (event) => {
    keyStageSetter(event.target.value);
  }

  return (
    <div>
      <Typography variant="h6">Student Information:</Typography>
      <form className={classes.root} variant="outlined">
        <Select
          labelId="key-stage-choice-label"
          id="key-stage-choice"
          value={keyStage}
          onChange={handleKeyStageChange}
        >
          <MenuItem value={"KS3"}>Key Stage 3</MenuItem>
          <MenuItem value={"KS4"}>Key Stage 4</MenuItem>
          <MenuItem value={"KS5"}>Key Stage 5</MenuItem>
          <MenuItem value={"Tutor Reports"}>Tutor Reports</MenuItem>
        </Select>
        <Box component="span">
          <FormControlLabel value="female" control={<Radio
            checked={pronounChoice === 'female'}
            onChange={handlePronounsChange}
            name="pronouns-radio"
          />} label="Female" />
          <FormControlLabel value="male" control={<Radio
            checked={pronounChoice === 'male'}
            onChange={handlePronounsChange}
            name="pronouns-radio"
          />} label="Male" />
        </Box>
        <TextField className={classes.textField} id="student-name" label="Name" variant="outlined" onChange={handleNameChange}/>
        <TextField className={classes.textField} id="student-target-grade" label="Target Grade" variant="outlined" onChange={handleTargetGradeChange}/>
        <TextField className={classes.textField} id="student-achieved-grade" label="Achieved Grade" variant="outlined" onChange={handleAchievedGradeChange}/>
      </form>
    </div>
  );
}
