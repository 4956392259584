import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider } from '@material-ui/core';

import StudentInfo from './StudentInfo';
import CommentPicker from './CommentPicker';
import CommentCombiner from './CommentCombiner';

export const CURSOR = "$$$Cursor$$$";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    margin: "10px",
    padding: "24px",
    borderRadius: "4px",
  },
  borderRight: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
  },
  button: {
    margin: theme.spacing(0.5),
  }
}));

function ReportGenerator() {
  const classes = useStyles();

  const [comments, setComments] = useState([CURSOR]);
  const [studentInfo, setStudentInfo] = useState({});
  const [keyStage, setKeyStage] = useState("KS3");

  function addComment(comment) {
    const cursorLocation = comments.indexOf(CURSOR)
    comments.splice(cursorLocation, 0, comment)
    setComments([...comments]);
  }

  const removeCommentAt = (index) => {
    comments.splice(index, 1);
    setComments([...comments]);
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={8} className={classes.borderRight}>
          <StudentInfo studentInfoSetter={setStudentInfo} studentInfo={studentInfo} keyStage={keyStage} keyStageSetter={setKeyStage} />
          <Divider/>
          <CommentPicker commentAdder={addComment} studentInfo={studentInfo} keyStage={keyStage}/>
        </Grid>

        <Grid item xs={4}>
          <CommentCombiner
            removeCommentAt={removeCommentAt}
            setComments={setComments}
            comments={comments}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReportGenerator;
