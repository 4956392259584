import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },
  listItemText: {
    flexGrow: 1,
  }
}));

export default function Comment(props) {
  const { commentAdder, content } = props;

  const classes = useStyles();

  // const content = 'Ronan has been working consistently hard this year and I’m impressed with his efforts.';

  function addComment(e) {
    commentAdder(content);
  }

  return (
    <div className={classes.root}>
      <ListItem button onClick={addComment}>
        <ListItemText className={classes.listItemText} primary={content} />
        <ListItemIcon>
          <ArrowForwardRoundedIcon fontSize="large" color="secondary"/>
        </ListItemIcon>
      </ListItem>
    </div>
  );
};
