import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
} from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';

import Mustache from 'mustache';

import CommentSection from './CommentSection';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function CommentPicker(props) {
  const classes = useStyles();
  // const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sections, setSections] = useState([]);

  const {commentAdder, studentInfo, keyStage} = props;

  useEffect(() => {
    fetch("https://msandrews.mur-phy.com/reports/api/sections")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setSections(result.items);
        },
        (error) => {
          setIsLoaded(true);
          // setError(error);
        }
      )
  }, []);

  function dummy_escape (str) {
    return str;
  }

  let renderedSections = sections.map(function(section) {
    let newSection = {
      ...section,
      quotes: section.quotes.map(function(quote) {
        let newQuote = {
          ...quote,
          content: Mustache.render(
            // Hack to disable escaping, don't want to triple quote everything in content.
            quote.content, studentInfo, {}, {escape: dummy_escape}
          )
        }
        return newQuote;
      })
    };
    return newSection;
  });

  // Based on the keyStage, we want to filter out some of the quotes completely.
  let filteredQuotes = renderedSections.map(function(section) {
    let newSection = {
      ...section,
      quotes: section.quotes.filter(function(quote) {
        const hasNoTags = quote.tags.length === 0;
        const tagNames = quote.tags.map(tag => tag.name);
        const hasTagsMatchingChosenKeyStage = tagNames.includes(keyStage);
        return hasNoTags || hasTagsMatchingChosenKeyStage;
      })
    };
    return newSection;
  });

  // Based on the now filtered quotes, remove sections that have no quotes.
  let filteredSections = filteredQuotes.filter(function(section) {
    return section.quotes.length > 0;
  });

  return (
    !isLoaded ? <CircularProgress /> :
    <div className={classes.root}>
      {filteredSections.map(section => (
        <CommentSection
          commentAdder={commentAdder}
          name={section.name}
          quotes={section.quotes}
          key={section.id}
        />
      ))}
    </div>
  );
};
